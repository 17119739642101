.modal {
  padding-top: 30px;

  h4.modal-header-title {
    font-size: 2em;
    text-align: center;
    margin: 1rem 0 1em 0;
    font-weight: 800;
  }

  .modal-body {
    padding: 2.5em 3em;
  }

  .modal-footer button {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .modal-content {
    position: relative;

    span.mod-close {
      width: 35px;
      height: 35px;
      position: absolute;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
      background: transparent;
      box-shadow: none;
      right: -11px;
      top: -35px;

      i {
        display: block;
        color: $white;
        font-size: 1.5em;
      }
    }
  }

  .signup {
    .form-group {
      margin-bottom: 1.5rem;
    }


    .modal-dialog {
      max-width: 880px;
      z-index: +999999;
    }
  }

  .sign-in {
    margin: 1rem 0 2rem 0;

    i {
      margin-right: 8px;
    }
  }

  .custom-file {
    height: auto;
  }
}

@include media-breakpoint-up(lg) {
  .modal-dialog {
    max-width: 600px;
    margin: 30px auto;
  }
}

@include media-breakpoint-down(sm) {
  .modal {
    font-size: 0.8em;

    .modal-body {
      padding: 1.5em;
    }
  }
}
